<template>
    <div class="menu-content profile">
        <main-nav-header></main-nav-header>
        <div class="content">
            <div class="row row-cols-1 mb-5">
                <div class="col">
                    <profile-box></profile-box>
                </div>
            </div>

            <ul class="nav nav-tabs mb-4" role="tablist">
                <li v-for="(item,index) in tabs" class="nav-item" :key="index" role="presentation">
                    <a aria-controls="home" aria-selected="true" class="nav-link" :class="{'active': index == 0}" data-toggle="tab"
                       :href="'#basic'+ item" role="tab">{{item}}</a>
                </li>
            </ul>

            <div class="row row-cols-2">
                <div class="col-4">
                    <div class="row row-cols-1">

                        <div class="col mb-4">
                            <layout-box with-header with-dropdown >
                                <template #header>Header Title</template>
                                <template #dropdown>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </template>
                                <table class="table w-100">
                                    <tbody>
                                        <tr v-for="(n,index) in 3" :key="index">
                                            <td class="border-0 px-0">Title:</td>
                                            <td class="border-0 px-0">Value</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </layout-box>
                        </div>

                        <div class="col">
                            <layout-box with-header with-dropdown>
                                <template #header>Header Title</template>
                                <template #dropdown>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </template>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet</p>
                            </layout-box>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <layout-box with-header with-dropdown>
                        <template #header>Header Title</template>
                        <template #dropdown>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Separated link</a>
                        </template>
                        <div class="d-flex align-items-center mb-4 mt-3" v-for="(n,index) in 4" :key="index">
                            <div class="pr-4">
                                <avatar size="md" type="icon" variant="custom-apple-green">
                                    <fa class="text-success-light" icon="check"></fa>
                                </avatar>
                            </div>
                            <div>
                                <div class="font-weight-semibold">Invoice name</div>
                                <div>Description, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Laoreet suspendisse</div>
                            </div>

                            <div class="text-right">
                                <div class="font-weight-bold">R14,765</div>
                                <div class="font-12 no-wrap">24 may 2021</div>
                            </div>
                        </div>
                    </layout-box>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import MainNavHeader from "../../../src/components/MainNavHeader/MainNavHeader";
    import LayoutBox from "../../../src/components/LayoutBox/LayoutBox";
    import ProfileBox from "../../../src/components/ProfileBox/ProfileBox";
    import Avatar from "../../../src/components/Avatar/Avatar";

    export default {
        name: "Profile",
        components: {
            MainNavHeader,
            LayoutBox,
            ProfileBox,
            Avatar
        },
        data() {
            return {
                tabs: ['First', 'Second', 'Third', 'Fourth']
            }
        },
        mounted() {
            this.$emit('toggleMenu','main', false);
        },
    }
</script>

