<template>
    <div class="profile-box">
        <div class="profile-img border border-light">
            <img src="https://i.pravatar.cc/350?img=68" alt="">
        </div>
        <layout-box class="border-left" with-dropdown with-header>
            <template #header>Header Title</template>
            <template #dropdown>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
            </template>
            <div class="font-20 font-weight-semibold">Brandon Williamson</div>
            <p class="profile-text mb-2">Lead paragraph. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.</p>
            <div class="mb-2">
                <span class="font-weight-semibold mr-2">6.2</span>
                <span>
                     <fa class="text-gray-700" icon="star"></fa>
                     <fa class="text-gray-700" icon="star"></fa>
                     <fa class="text-gray-700" icon="star"></fa>
                     <fa class="text-gray-400" icon="star"></fa>
                     <fa class="text-gray-400" icon="star"></fa>
                </span>
            </div>
            <div>
                <div class="badge border border-info text-info mr-2">Big Tech</div>
                <div class="badge border border-info text-info mr-2">Big Data Processing</div>
            </div>
        </layout-box>
        <layout-box class="border-left" with-dropdown with-header>
            <template #header>Header Title</template>
            <template #dropdown>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Separated link</a>
            </template>
        </layout-box>
    </div>
</template>

<script>
    import LayoutBox from "../LayoutBox/LayoutBox";

    export default {
        name: "ProfileBox",
        components: {
            LayoutBox
        }
    }
</script>
